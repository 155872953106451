<template>
    <div class="pricing-container">
        <div class="pricing-header">
            <h1>一站式AI创意设计服务平台</h1>
            <p style="padding: 30px 0px;">海量正版内容 · 正版商用授权 · 高清无水印下载 · 图像制作共享 · AI智能设计 · 企业级本地效</p>
        </div>
        <div class="juzhong" style="width: 65%;height: auto;margin: 0 auto;margin-top: 20px;">
            <el-row :gutter="20">
                <el-col :span="6" v-for="(plan, index) in plans" :key="index">
                    <div :class="['plan', plan.class]">
                        <div class="plan-header">
                            <div :style="plan.bg" style="width: calc(100% - 40px);height: 80px;padding: 20px;border-radius: 10px;">
                                <h2 :style="index == 3 ?'color: #ffd092':''">{{ plan.name }}</h2>
                                <p  :style="index == 3 ?'color: hsla(0,0%,100%,.8)':'color:#505a71'" style="line-height: 40px;">{{ plan.description }}</p>
                            </div>

                            <div class="price" style="height: 100px;">
                               <div v-if="index <= 2 " style="line-height: 100px;">
                                   <span>￥<span style="font-size: 24px;font-weight: bold;padding: 0 5px;">{{ plan.price }}</span></span>
                                   <span>/月</span>
                               </div>
                                <div v-if="index == 3" >
                                    <img style="width: 80px;height: 80px;margin: 0 auto;margin-top: 10px;"  src="@/assets/imgs/vip/xcxcode4.jpg">
                                </div>
                            </div>
                            <div @click="isPayWin = true" :style="plan.p1"  style="width: 90%;line-height: 38px;border-radius: 8px;text-align: center;font-size: 14px;margin: 0 auto;font-weight: 600;cursor: pointer;">
                                {{ plan.buttonText }}
                            </div>
                        </div>
                        <div class="plan-features">
                            <div v-for="(item, index) in plan.titleList" :key="index" style="width: 90%;height: auto;margin: 0 auto;margin-top: 12px;">
                                <span style="color: #1b2337;font-weight: 600;font-size: 14px;line-height: 32px;">{{ item.title }}</span>
                                <div v-for="(ite, indx) in item.features" :key="indx" style="display: flex;">
                                    <div style="width: 245px;height: 20px;color: #505a71;font-size: 12px;line-height: 30px;margin-bottom: 12px;">
                                        {{ite}}
                                    </div>
                                    <div>
                                        <img style="width: 16px;height: 16px;"  src="@/assets/imgs/vip/pre-check.fef3b1ec.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div style="width: 65%;height: auto;margin: 0 auto;">
            <el-row>
                <el-col :span="24">
                    <h2 style="text-align: center; margin-top:60px;margin-bottom:20px;font-size: 36px;color: #1b2337;font-weight: 600;height: 100px;">会员权益对比</h2>
                </el-col>
            </el-row>
            <div style="width: 100%;height: 66px;background: #F5F7FD;display: flex;font-size: 14px;">
                <div style="width: 25%;line-height: 66px;text-align: center;"> 授权范围对比</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 免费版</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 通用版</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 协作版</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 旗舰版</div>
            </div>
            <div v-for="(item,index) in tableData" :key="index">
                <div  style="width: 100%;height: 86px;display: flex;font-size: 14px;" :style="index < tableData.length - 1 ? 'border-bottom: 1px solid #EBEEF5':''">
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.feature}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.free}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.standard}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.cooperation}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.custom}}</div>
                </div>
            </div>
        </div>

        <div style="width: 65%;height: auto;margin: 0 auto;">
            <el-row>
                <el-col :span="24">
                    <h2 style="text-align: center; margin-top:60px;font-size: 36px;color: #1b2337;font-weight: 600;height: 100px;">常见问题</h2>
                </el-col>
            </el-row>
            <!-- FAQ Section -->
            <div v-for="(faq, index) in faqs" :key="index" class="faq-item" @click="toggleFAQ(index)">
                <div class="faq-question">
                    <span>{{ faq.question }}</span>
                    <span class="arrow" :class="{ open: faq.open }">▼</span>
                </div>
                <div v-if="faq.open" class="faq-answer">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
        <div class="dialog-container">
            <el-dialog :visible.sync="isPayWin" width="1200px" custom-class="login-dialog" class="custom-dialog">
                <ada-pay-win />
            </el-dialog>
        </div>
    </div>
</template>

<script>
import AdaPayWin from "./adaPayWin.vue";
export default {
    components: {AdaPayWin},
    data() {
        return {
            isPayWin:false,
            plans: [
                {
                    name: '免费版',
                    description: '适用于个人体验学习使用',
                    price: '0',
                    buttonText: '免费体验',
                    buttonType: 'primary',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    class: 'free-plan',
                    bg:'background:linear-gradient(104deg,#ecf1fb 1.63%,#d5dcec 99.05%)',
                    p1:' color: #0773fc;border:1px solid #0773fc;',
                },
                {
                    name: '通用版',
                    description: '适用于个人或企业少量人使用',
                    price: '6',
                    buttonText: '立即开通',
                    buttonType: 'primary',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    class: 'standard-plan',
                    bg:'background:linear-gradient(96deg,#cee8fe 24.89%,#8ec0ff 91.29%)',
                    p1:'color: #FFFFFF;background:linear-gradient(90deg,#3d92ff .09%,#0773fc 101.47%);'
                },
                {
                    name: '协作版',
                    description: '适用于2-10人团队协作',
                    price: '199',
                    buttonText: '立即开通',
                    buttonType: 'warning',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    class: 'collaboration-plan',
                    bg:'background:linear-gradient(90deg,#fceee1,#ffd5b6)',
                    p1:'color: #6b3d1e;background:linear-gradient(90deg,#fceee1,#ffc9a0),linear-gradient(270deg,#4d65a4,#5f78b7)'
                },
                {
                    name: '旗舰版',
                    description: '适用于大中型企业/跨职能业务子公司',
                    price: '咨询客服',
                    buttonText: '预约演示',
                    buttonType: 'default',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    class: 'enterprise-plan',
                    bg:'background:linear-gradient(90deg,#3b404a 1%,#07080a)',
                    p1:'color: #ffd092;background:linear-gradient(270deg,#2b2c31,#4e4f53)'
                }
            ],
            tableData: [
                {
                    feature: '价格',
                    free: '免费使用',
                    standard: '¥6/月起',
                    cooperation: '¥199/年/人起',
                    custom: '专业个性化服务'
                },
                {
                    feature: '起购人数',
                    free: '✗',
                    standard: '1人',
                    cooperation: '2人',
                    custom: '可定制'
                },
                {
                    feature: '可购人数上限',
                    free: '✗',
                    standard: '1人',
                    cooperation: '10人',
                    custom: '无上限'
                },
                {
                    feature: '授权类型',
                    free: '仅限个人学习交流',
                    standard: '个人/企业商用授权 *1个（可追加）',
                    cooperation: '企业商用授权 *1个（可追加）',
                    custom: '企业商用授权 *1个（可选购）'
                }
            ],
            faqs: [
                {
                    question: '01. 通用版、协作版、旗舰版之间的区别是什么？',
                    answer: '通用版: 适合单人使用，提供海量的商用模板素材，授权主体包含：个人或企业授权，支持高清无水印下载等功能的授权版。协作版：支持10人以内的在线协作共享，授权为企业授权，支持批量设计，内容标签管理，享受多于通用版的功能与权益。 旗舰版：享受协作版全部功能外，额外拥有品牌管控、安全合规、数据可视化等功能权益。同时，支持授权主体与账号定制，适用于大型企业/跨地域多子公司等。 联系专属顾问',
                    open: false
                },
                {
                    question: '02. 我是企业员工，我自己使用可以购买通用版个人吗？',
                    answer: '是的，企业员工可以购买通用版个人使用。',
                    open: false
                },
                {
                    question: '03. 如何申请发票？需要多长时间？',
                    answer: '申请发票流程如下：登录账户 -> 进入订单管理 -> 申请发票。一般需要3-5个工作日处理。',
                    open: false
                },
            ]
        }
    },
    methods:{
        toggleFAQ(index) {
            this.faqs[index].open = !this.faqs[index].open;
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.pricing-container {
    text-align: center;
    padding: 40px 20px;
    background: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

.pricing-header h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #333;
}

.pricing-header p {
    font-size: 16px;
    color: #666;
}

.pricing-tables {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.plan {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(42,49,67,.06);
    transition: transform 0.3s ease;
    text-align: left;
    border:1px solid #fff;
}

.plan:hover {
    transform: translateY(-10px);
}

.plan-header {
    margin-bottom: 20px;
}

.plan-header h2 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

.plan-header p {
    font-size: 14px;
    color: #999;
}

.price {
    color: #333;
    text-align: center;
}

h2 {
    font-weight: bold;
}

/* FAQ Section */
.faq-section {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.faq-item {
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background 0.3s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.faq-item:hover {
    background: #f0f0f0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
    text-align: left;
}

.arrow {
    transition: transform 0.3s;
}

.arrow.open {
    transform: rotate(180deg);
}
/deep/ .dialog-container .el-dialog__wrapper {
    border-radius: 10px !important;
}

/deep/ .dialog-container .el-dialog {
    border-radius: 10px !important;
}

</style>
